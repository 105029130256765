import { IViewRowOperationsCommand } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ViewResultRow } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ICommand } from '../models/ETG_SABENTISpro_Application_Core_models';
import { QUALA_STUDY } from '../models/ETG_SABENTISpro_Models_models';

export interface ITypedObject {
  readonly $type: string;
}

/**
 * INSHTModule Permissions (contants)
 */
export class InshtModulePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.INSHT.InshtModulePermissions';
  readonly $type: string = InshtModulePermissions.$type;
}


/**
 * FineModule Permissions (contants)
 */
export class FineModulePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Fine.FineModulePermissions';
  readonly $type: string = FineModulePermissions.$type;
}


/**
 */
export enum CalendarResponsiblePermissionEnum {
  Read = 0,
  ReadAndUpdate = 1
}


/**
 * Calendar event status color constants
 */
export class CalendarEventStatusColorEnum implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Calendar.Enums.CalendarEventStatusColorEnum';
  public static readonly PENDIENTE: string = '16753920';
  public static readonly RECHAZADO: string = '16711680';
  public static readonly REALIZADO: string = '32768';
  readonly $type: string = CalendarEventStatusColorEnum.$type;
}


/**
 * Calendar Type Code Constants
 */
export enum CalendarAllDayEnum {
  PartialDay = 0,
  AllDay = 1
}


/**
 * Calendar Type Code Constants
 */
export class CalendarTypeCodeEnum implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Calendar.Enums.CalendarTypeCodeEnum';
  public static readonly TECHNICAL: string = '1';
  public static readonly VS: string = '2';
  public static readonly INTERNAL: string = '3';
  readonly $type: string = CalendarTypeCodeEnum.$type;
}


/**
 */
export class LetsTalkConfigDTO implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.LetsTalk.DTO.LetsTalkConfigDTO';
  readonly $type: string = LetsTalkConfigDTO.$type;
  Active: number;
  URL: string;
}


/**
 * Enum de las ubicaciones para las carteras
 */
export enum LocationTypeEnum {
  COMPANY = 0,
  PRODUCTIVEUNIT = 1
}


/**
 * Enum de los tipos de ubicación de una cartera
 */
export enum PortfolioLocationTypeEnum {
  COMPANY = 0,
  PRODUCTIVEUNIT = 1
}


/**
 * SabentisModule Permissions (contants)
 */
export class HiraModulePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Hira.HiraModulePermissions';
  public static readonly SeeHira: string = 'see hira';
  public static readonly SeeQuestionnaires: string = 'see questionnaires';
  public static readonly AnswerQuestionnaires: string = 'answer questionnaires';
  public static readonly AddQuestionaries: string = 'add questionaries';
  public static readonly RemoveQuestionnaires: string = 'remove questionaries';
  public static readonly SeeInventory: string = 'see inventory';
  public static readonly CopyProblems: string = 'copy problems';
  public static readonly SeeEvaluation: string = 'see evaluation';
  public static readonly EvaluateProblems: string = 'evaluate problems';
  public static readonly ConfigMethodology: string = 'configuration methodology';
  public static readonly CreateCorrectiveActions: string = 'create corrective actions';
  public static readonly SeePredefinedCorrectiveAction: string = 'see predefined corrective actions';
  public static readonly ManagePredefinedCorrectiveAction: string = 'manage predefined corrective actions';
  public static readonly AddPredefinedCorrectiveAction: string = 'add predefined corrective actions';
  public static readonly RemovePredefinedCorrectiveAction: string = 'delete predefined corrective actions';
  public static readonly EditAssignProblemsStructure: string = 'edit assign problems structure';
  public static readonly AddCorrectiveActionFromLibrary: string = 'add corrective action from library';
  public static readonly DownloadQuestionnairePdf: string = 'hira download questionnairepdf';
  public static readonly SeeMeasuresSupportMaterial: string = 'hira measures support material see';
  public static readonly SeeAgrupationSupportMaterial: string = 'hira agrupation support material see';
  readonly $type: string = HiraModulePermissions.$type;
}


/**
 * SabentisModule Permissions (contants)
 */
export class GesModulePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Ges.GesModulePermissions';
  public static readonly SeeGes: string = 'see ges';
  public static readonly SeeQuestionnaires: string = 'see questionnaires';
  public static readonly AnswerQuestionnaires: string = 'answer questionnaires';
  public static readonly SeeInventory: string = 'see inventory';
  public static readonly ManageGES: string = 'manage ges';
  public static readonly EditDataLocationsInitialGes: string = 'edit data locations initial ges';
  public static readonly EditDataLocationsFinalGes: string = 'edit data locations final ges';
  public static readonly QualitativeClosureGES: string = 'qualitative closure ges';
  public static readonly SeeGES: string = 'see ges';
  public static readonly CreateGES: string = 'create initial ges';
  public static readonly RemoveGES: string = 'delete initial ges';
  public static readonly EditInitialGES: string = 'edit initial ges';
  public static readonly EditFinalGES: string = 'edit final ges';
  public static readonly SeeQualitativeEvaluationGES: string = 'see qualitative evaluation ges';
  public static readonly CreateQualitativeEvaluationGES: string = 'create qualitative evaluation ges';
  public static readonly CreateCorrectiveActions: string = 'ges create corrective actions';
  public static readonly SeeMeasurementPlan: string = 'see measurementplan';
  public static readonly ManageMeasurementPlan: string = 'manage measurementplan';
  public static readonly RemoveMeasurementPlan: string = 'remove measurementplan';
  public static readonly SeeMeasurements: string = 'see measurements';
  public static readonly ManageMeasurements: string = 'manage measurements';
  public static readonly RemoveMeasurements: string = 'remove measurements';
  public static readonly SeeResults: string = 'see results';
  public static readonly SeeActionPlan: string = 'ges see action plan';
  readonly $type: string = GesModulePermissions.$type;
}


/**
 * Single Sign On Permissions (contants)
 */
export class SingleSignOnModulePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.SingleSignOn.SingleSignOnModulePermissions';
  public static readonly ManageSSO: string = 'manage sso';
  readonly $type: string = SingleSignOnModulePermissions.$type;
}


/**
 */
export class AuthenticateResult implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.SingleSignOn.Dto.AuthenticateResult';
  readonly $type: string = AuthenticateResult.$type;
  valid: boolean;
  callback_uri: string;
  url_destination: string;
  tokens: Tuple<string, string>;
}


/**
 * Salud ocupacional Permissions (contants)
 */
export class SaludOcupacionalModulePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.SaludOcupacional.SaludOcupacionalModulePermissions';
  public static readonly VerModulo: string = 'occupational health module see';
  public static readonly VerDatosPersonalesSaludOcupacionalHistoriaClinica: string = 'see personal data occupational health clinical history';
  public static readonly VerRestriccionesSensibilidadesTrabajadorSaludOcupacionalHistoriaClinica: string = 'see restrictions sensitivities employee occupational health clinical history';
  readonly $type: string = SaludOcupacionalModulePermissions.$type;
}


/**
 * Quala_problem type
 */
export enum SafetyInspectionProblemTypeEnum {
  Normativa = 0,
  SpecificRevision = 1
}


/**
 * SafetyInspectionsStatusEnums
 */
export enum SafetyInspectionsStatusEnums {
  ABIERTA = 0,
  CERRADA = 1
}


/**
 * Class of Permisions
 */
export class SabentisResponsiblePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.SabentisResponsible.SabentisResponsiblePermissions';
  public static readonly VerMantenedorResponsabilidades: string = 'sabentisresponsible mantenedor responsabilidades ver';
  public static readonly VerModuloResponsabilidades: string = 'sabentisresponsible modulo responsabilidades ver';
  public static readonly VerResponsableEmpresa: string = 'sabentisresponsible responsable empresa ver';
  public static readonly VerResponsableCentroTrabajo: string = 'sabentisresponsible responsable centro trabajo ver';
  public static readonly EditarResponsableEmpresa: string = 'sabentisresponsible responsable empresa editar';
  public static readonly EditarResponsableCentroTrabajo: string = 'sabentisresponsible responsable centro trabajo editar';
  public static readonly VerFunciones: string = 'sabentisresponsible funciones ver';
  public static readonly EditarFunciones: string = 'sabentisresponsible funciones editar';
  public static readonly GestionResponsabilidades: string = 'sabentisresponsible responsabilidades gestion';
  readonly $type: string = SabentisResponsiblePermissions.$type;
}


/**
 * Class of Permisions
 */
export class SabentisPolicyPermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.SabentisPolicy.SabentisPolicyPermissions';
  public static readonly SeePolicieSst: string = 'see policies sst';
  public static readonly ManagePoliciesSst: string = 'manage policies sst';
  readonly $type: string = SabentisPolicyPermissions.$type;
}


/**
 * Tipos de Status Políticas
 */
export enum StatusPolicyEnum {
  Redaccion = 1,
  Pendiente = 2,
  Rechazada = 3,
  Vigente = 4,
  Caducada = 5
}


/**
 * The Quala Problem status
 */
export enum PymesIperQualaProblemStatusEnums {
  Discarded = 0,
  Identified = 1,
  Validated = 5
}


/**
 * PymesIperRiskViewCommand
 */
export class PymesIperRiskViewCommand implements IViewRowOperationsCommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.PymesIper.Command.PymesIperRiskViewCommand';
  readonly $type: string = PymesIperRiskViewCommand.$type;
  Field: string;
  RiskId: string;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
  Result: ViewResultRow;
}

/**
 * SabentisModule Permissions (contants)
 */
export class PymesModulePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Pymes.PymesModulePermissions';
  public static readonly SeePymesModule: string = 'see pymes module';
  public static readonly SeePymesEo: string = 'see pymes eo';
  public static readonly SeePymesEmpresa: string = 'see pymes empresa';
  public static readonly ProductiveUnitsBulkLoadOperation: string = 'add pymes productiveunits bulk load button';
  public static readonly JobsBulkLoadOperation: string = 'add pymes jobs bulk load button';
  public static readonly WorkersBulkLoadOperation: string = 'add pymes workers bulk load button';
  readonly $type: string = PymesModulePermissions.$type;
}


/**
 * Dto to send GA settings to client
 */
export class GoogleAnalyticsClientSettings implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.GoogleAnalytics.DTO.GoogleAnalyticsClientSettings';
  readonly $type: string = GoogleAnalyticsClientSettings.$type;
  TrackingCodes: string[];
  TrackingGTMCodes: string[];
}


/**
 * SabentisModule Permissions (contants)
 */
export class SabentisModulePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Sabentis.SabentisModulePermissions';
  public static readonly SeeStructureModule: string = 'see structure module';
  public static readonly SeeStatisticsCharts: string = 'see statistics charts';
  public static readonly CreateCompanies: string = 'create companies';
  public static readonly EditCompanies: string = 'edit companies';
  public static readonly SeeCompanies: string = 'see companies';
  public static readonly SeeCompanyDocuments: string = 'see company documents';
  public static readonly ManageCompanyDocuments: string = 'manage company documents';
  public static readonly ManagePersons: string = 'manage persons';
  public static readonly ManageAddresses: string = 'manage addresses';
  public static readonly CreateBusinessGroups: string = 'create businessgroups';
  public static readonly EditBusinessGroups: string = 'edit businessgroups';
  public static readonly DeleteBusinessGroups: string = 'delete businessgroups';
  public static readonly SeeBusinessGroups: string = 'see businessgroups';
  public static readonly SeeBusinessGroupsDocuments: string = 'see businessgroups documents';
  public static readonly ManageBusinessGroupsDocuments: string = 'manage businessgroups documents';
  public static readonly ManageProductiveUnits: string = 'manage productiveunits';
  public static readonly CreateProductiveUnits: string = 'create productiveunits';
  public static readonly EditProductiveUnits: string = 'edit productiveunits';
  public static readonly DeleteProductiveUnits: string = 'delete productiveunits';
  public static readonly SeeProductiveUnits: string = 'see productiveunits';
  public static readonly ManageContacts: string = 'manage contacts';
  public static readonly SeeContacts: string = 'see contacts';
  public static readonly CreateEmployee: string = 'create employee';
  public static readonly EditEmployee: string = 'edit employee';
  public static readonly DeleteEmployee: string = 'delete employee';
  public static readonly SeeEmployee: string = 'see employee';
  public static readonly SeeEmployeeDocuments: string = 'see employee documents';
  public static readonly ManageEmployeeDocuments: string = 'manage employee documents';
  public static readonly SeeCompanyProfiles: string = 'see profiles';
  public static readonly AsignCompanyProfiles: string = 'asign profiles';
  public static readonly SeeCompanyCriticality: string = 'see company criticality';
  public static readonly SeeCenterCriticality: string = 'see center criticality';
  public static readonly SeeWorkPlaces: string = 'see workplaces';
  public static readonly ManageWorkPlaces: string = 'manage workplaces';
  public static readonly SeeWorkPlaceDocuments: string = 'see workplaces documents';
  public static readonly ManageWorkPlaceDocuments: string = 'manage workplaces documents';
  public static readonly SeeJobs: string = 'see jobs';
  public static readonly SeeJobsRestrictions: string = 'see jobs restrictions';
  public static readonly ManageJobs: string = 'manage jobs';
  public static readonly CopyStructure: string = 'copy structure';
  public static readonly SeeOccupations: string = 'see occupation';
  public static readonly ManageOccupations: string = 'manage occupation';
  public static readonly SeeOccupationDocuments: string = 'see occupation documents';
  public static readonly ManageOccupationDocuments: string = 'manage occupation documents';
  public static readonly SeeFPL: string = 'see fpl';
  public static readonly ManageFPL: string = 'manage fpl';
  public static readonly SeeFPLDocuments: string = 'see fpl documents';
  public static readonly ManageFPLDocuments: string = 'manage fpl documents';
  public static readonly SeeProcess: string = 'see process';
  public static readonly ManageProcess: string = 'manage process';
  public static readonly SeeFOL: string = 'see fol';
  public static readonly ManageFOL: string = 'manage fol';
  public static readonly SeeFolDocuments: string = 'see fol documents';
  public static readonly ManageFolDocuments: string = 'manage fol documents';
  public static readonly ManageFreeLocationLevels: string = 'manage free location levels';
  public static readonly SeeAgent: string = 'see agent';
  public static readonly ManageAgent: string = 'manage agent';
  public static readonly AddChemicalAgentFromLibrary: string = 'sabentis library chemical agent add';
  public static readonly SeeFitting: string = 'see fitting';
  public static readonly ManageFitting: string = 'manage fitting';
  public static readonly SeeAgentDocuments: string = 'see agent documents';
  public static readonly ManageAgentDocuments: string = 'manage agent documents';
  public static readonly CloneCompany: string = 'clone company';
  public static readonly SeeConfigurationCompany: string = 'see configuration company';
  public static readonly SeeValidateStructureCompany: string = 'see validate structure company';
  public static readonly SeeEmployeeProductiveUnits: string = 'see employee productiveunits';
  public static readonly AssociateEmployeeProductiveUnits: string = 'associate employee productiveunits';
  public static readonly AssociateEmployeeJob: string = 'associate employee job';
  public static readonly DisassociateEmployeeJob: string = 'disassociate employee job';
  public static readonly EditEmployeeJob: string = 'edit employee job';
  public static readonly SeeHistoricalEmployeeJob: string = 'see historical employee job';
  public static readonly EditHistoricalEmployeeJob: string = 'edit historical employee job';
  public static readonly SeeProcessDocuments: string = 'see process documents';
  public static readonly ManageProcessDocuments: string = 'manage process documents';
  public static readonly SeeProducitveUnitDocuments: string = 'sabentis see productive unit documents';
  public static readonly ManageProductiveUnitDocuments: string = 'sabentis manage productive unit documents';
  public static readonly SeeJobDocuments: string = 'see job documents';
  public static readonly ManageJobDocuments: string = 'manage job documents';
  public static readonly BulkLoadButton: string = 'bulk load button';
  public static readonly SeeButtonQRInCompany: string = 'see button qr company';
  public static readonly SeeButtonQRInCenter: string = 'see button qr center';
  public static readonly ExportStructureTemplate: string = 'export structure template';
  public static readonly SeeStructureTemplate: string = 'see structure template';
  public static readonly EditStructureTemplate: string = 'edit structure template';
  public static readonly DeleteStructureTemplate: string = 'delete structure template';
  public static readonly ApplyStructureTemplate: string = 'apply structure template';
  public static readonly ChangeProductiveUnitStatus: string = 'change productive unit status';
  public static readonly ChangeCompanyStatus: string = 'change company status';
  public static readonly ManageCompaniesConfiguration: string = 'manage companies configurations';
  public static readonly CreateJob: string = 'create job';
  public static readonly PersonNoCompanyBulkLoadButton: string = 'person nocompany bulk load button';
  public static readonly PersonWithCompanyBulkLoadButton: string = 'person withcompany bulk load button';
  public static readonly ProductiveUnitBulkLoadButton: string = 'productiveunit bulk load button';
  public static readonly OccupationBulkLoadButton: string = 'occupation bulk load button';
  public static readonly WorkplaceBulkLoadButton: string = 'workplace bulk load button';
  public static readonly JobBulkLoadButton: string = 'job bulk load button';
  public static readonly WorkersBulkLoadButton: string = 'workers bulk load button';
  public static readonly SeeCoordinates: string = 'see coordinates';
  public static readonly EditCoordinates: string = 'edit coordinates';
  public static readonly ManageBusinessDivisions: string = 'manage business divisions';
  public static readonly SeeEOPersons: string = 'eo persons see';
  public static readonly EOPersonsBulkLoad: string = 'eo persons management bulk load';
  public static readonly ManageEOPersons: string = 'eo persons management';
  readonly $type: string = SabentisModulePermissions.$type;
}


/**
 * The object for the response that validates if the organization scruture is valid for the given company
 */
export class LocationValidation implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Sabentis.Dto.LocationValidation';
  readonly $type: string = LocationValidation.$type;
  CompanyName: string;
  Valid: boolean;
  Alerts: LocationValidationItem[];
  Errors: LocationValidationItem[];
}


/**
 * The object for the response that validates if the organization scruture is valid for the given company
 */
export class LocationValidationItem implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Sabentis.Dto.LocationValidationItem';
  readonly $type: string = LocationValidationItem.$type;
  Code: string;
  Text: string;
  Param: string;
}


/**
 * App Context
 */
export class SabentisContext implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Sabentis.Context.Dto.SabentisContext';
  readonly $type: string = SabentisContext.$type;
  selectedCompany: string;
  CurrentCompanyConfiguration?: string;
  selectedCompanyLocation: string;
  selectedCompanyPath: string;
  selectedCompanyLabel: string;
  selectedCenter: string;
  selectedCenterLocation: string;
  selectedCenterPath: string;
  selectedCenterLabel: string;
  selectedCenterSecondaryLabel: string;
  editableCompany: boolean;
  editableCenter: boolean;
  visibleCenter: boolean;
  selectedCompanyCIF: string;
}


/**
 */
export enum AgentProtectionMethodsLocationTypeEnum {
  PU = 0,
  WP = 1
}


/**
 * Sí / Parcial /No
 */
export enum YesNoPartialEnum {
  Yes = 0,
  No = 1,
  Partial = 2
}


/**
 * Laboral status types
 */
export enum LaboralStatusEnum {
  Alta = 0,
  Ausente = 1,
  Suspendido = 2
}


/**
 * Scope Constants
 */
export class ScopeEnums implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Sabentis.Enums.ScopeEnums';
  public static readonly SCOPE_BUSINESSGROUP: string = 'BG';
  public static readonly SCOPE_COMPANY: string = 'C';
  public static readonly SCOPE_PRODUCTIVEUNIT: string = 'PU';
  public static readonly SCOPE_WORKPLACE: string = 'WP';
  public static readonly SCOPE_OCCUPATION: string = 'OC';
  public static readonly SCOPE_ZONE: string = 'Z';
  public static readonly SCOPE_LEVEL_ZONE: string = '4';
  public static readonly SCOPE_AREA: string = 'A';
  public static readonly SCOPE_SURFACE: string = 'S';
  public static readonly SCOPE_LEVEL_ALL: string = '-1';
  public static readonly SCOPE_LEVEL_COMPANYGROUP: string = '0';
  public static readonly SCOPE_LEVEL_COMPANY: string = '1';
  public static readonly SCOPE_LEVEL_CENTER: string = '2';
  public static readonly SCOPE_LEVEL_WORKPLACE: string = '3';
  public static readonly SCOPE_ACTIVITY: string = 'ACT';
  public static readonly SCOPE_LEVEL_ACTIVITY: string = '5';
  public static readonly SCOPE_TASK: string = 'TA';
  public static readonly SCOPE_SUBTASK: string = 'STA';
  public static readonly SCOPE_LEVEL_SURFACE: string = '6';
  public static readonly SCOPE_LEVEL_OCCUPATION: string = '3';
  public static readonly SCOPE_LEVEL_SUBTASK: string = '6';
  public static readonly SCOPE_AGENT: string = 'AG';
  public static readonly SCOPE_NAME_JOB: string = 'Puesto de Trabajo';
  public static readonly SCOPE_JOB: string = 'JOB';
  readonly $type: string = ScopeEnums.$type;
}


/**
 * Class of Permisions
 */
export class RiskReleasePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.RiskRelease.RiskReleasePermissions';
  public static readonly SeeRelease: string = 'riskrelease see release';
  public static readonly EditRelease: string = 'riskrelease edit release';
  public static readonly CreateRelease: string = 'riskrelease create release';
  public static readonly DeleteRelease: string = 'riskrelease delete release';
  public static readonly SeeRiskReleaseGraphs: string = 'riskrelease see graphs';
  public static readonly EditReleaseEmployee: string = 'riskrelease edit employee release';
  public static readonly SeePlanificacionComunicados: string = 'riskrelease planificacion see';
  public static readonly CorrectiveActionAdd: string = 'riskrelease correctiveaction add';
  readonly $type: string = RiskReleasePermissions.$type;
}


/**
 * Geographic scope Constants
 */
export enum GeographicScopeEnum {
  Activity = 0,
  GeographicArea = 1,
  Company = 2
}


/**
 * SabentisAuditModule Permissions (contants)
 */
export class SabentisAuditModulePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.SabentisAudit.SabentisAuditModulePermissions';
  public static readonly SeeAudit: string = 'see sabentisaudit';
  public static readonly SeeAllEvaluationsAudit: string = 'see all evaluations audit';
  public static readonly CreateEvaluationsAudit: string = 'create evaluations audit';
  public static readonly RemoveEvaluationsAudit: string = 'remove evaluations audit';
  public static readonly StudyDataSee: string = 'audit studydata see';
  public static readonly StudyDataEdit: string = 'audit studydata edit';
  public static readonly EvaluationSee: string = 'audit evaluations see';
  public static readonly EvaluationExport: string = 'audit evaluations export';
  public static readonly EvaluationEdit: string = 'audit evaluations edit';
  public static readonly FinishEvaluationAudit: string = 'finish evaluations audit';
  public static readonly PlanSee: string = 'audit plan see';
  public static readonly PlanEdit: string = 'audit plan edit';
  public static readonly CreateCorrectiveActionsAudit: string = 'create corrective actions audit';
  public static readonly SeeChartsResultsAudit: string = 'see charts results audit';
  public static readonly EditChartsResultsAudit: string = 'edit charts results audit';
  public static readonly SeeSummaryAudit: string = 'see summary audit';
  public static readonly EditSummaryAudit: string = 'edit summary audit';
  public static readonly SeeRequiredDocumentsAudit: string = 'see required documents audit';
  public static readonly ManageRequiredDocumentsAudit: string = 'manage required documents audit';
  public static readonly SeeGenerationDocumentAudit: string = 'see generation document audit';
  public static readonly ManageGenerationDocumentAudit: string = 'manage generation document audit';
  public static readonly SeeAndDownloadDocumentAudit: string = 'see download document audit';
  public static readonly ExtendAudit: string = 'extend evaluations audit';
  public static readonly FinishAudit: string = 'finish audit';
  public static readonly ReevaluateAudit: string = 'reevaluate audit';
  public static readonly SeePlanificationAudit: string = 'see planification audit';
  public static readonly SeeComparativeAudit: string = 'see comparative audit';
  public static readonly ExpireStudy: string = 'expire study';
  readonly $type: string = SabentisAuditModulePermissions.$type;
}


/**
 * Audit Auditor Team Code Constants
 */
export class AuditAuditorTeamCodeEnum implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.SabentisAudit.Enums.AuditAuditorTeamCodeEnum';
  public static readonly AUDITOR_JEFE: string = '1';
  public static readonly EQUIPO_AUDITOR: string = '2';
  public static readonly OBSERVADORES: string = '3';
  public static readonly GUIAS: string = '4';
  public static readonly EXPERTOS_TECNICOS: string = '5';
  readonly $type: string = AuditAuditorTeamCodeEnum.$type;
}


/**
 * AuditPeriodicidadEnums
 */
export enum AuditPeriodicidadEnums {
  MENSUAL = 1,
  TRIMESTRAL = 2,
  SEMESTRAL = 3,
  ANUAL = 4
}


/**
 * AuditPeriodoProrrogaEnums
 */
export enum AuditPeriodoProrrogaEnums {
  UNMES = 1,
  TRESMESES = 2,
  SEISMESES = 3,
  UNANYO = 4
}


/**
 * AuditStatusEnums
 */
export enum AuditStatusEnums {
  ABIERTO = 0,
  VIGENTE = 1,
  EXPIRADO = 2,
  CUESTIONARIOFINALIZADO = 3
}


/**
 */
export enum AuditISOsEnum {
  ISO9001 = 0,
  ISO14001 = 1,
  ISO37001 = 2,
  ISO45001 = 3,
  ISO39001 = 4,
  METODO5S = 5,
  ISO22301 = 6,
  ISO26000 = 7,
  ISO50001 = 8
}


/**
 * The Quala Questionaire evaluation status
 */
export enum AuditStudyQuestionnaireEvaluationStatusEnums {
  NoEvaluar = 0,
  Evaluar = 1
}


/**
 * Class of Permisions
 */
export class OPTPermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.OPT.OPTPermissions';
  public static readonly SeeObservaciones: string = 'inspeccionesplanificadas inspeccion see';
  public static readonly CreateObservaciones: string = 'inspeccionesplanificadas inspeccion create';
  public static readonly EditObservaciones: string = 'inspeccionesplanificadas inspeccion edit';
  public static readonly DeleteObservaciones: string = 'inspeccionesplanificadas inspeccion delete';
  public static readonly GenerateInformeObservaciones: string = 'inspeccionesplanificadas informeinspeccion create';
  public static readonly SeeNoConformidades: string = 'inspeccionesplanificadas observacion see';
  public static readonly CreateNoConformidades: string = 'inspeccionesplanificadas observacion create';
  public static readonly EditNoConformidades: string = 'inspeccionesplanificadas observacion edit';
  public static readonly DeleteNoConformidades: string = 'inspeccionesplanificadas delete edit';
  public static readonly CreateCorrectiveActions: string = 'inspeccionesplanificadas correctiveaction create';
  readonly $type: string = OPTPermissions.$type;
}


/**
 * Dto para la configuración de niveles de probabilidad y consecuencia
 */
export class Gtc45CommonLevelConfiguration implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.IperGtc45.Dto.Gtc45CommonLevelConfiguration';
  readonly $type: string = Gtc45CommonLevelConfiguration.$type;
  Levels: { [id: string]: Gtc45CommonLevelConfigurationItem };
}


/**
 * Configuración de nivel de probabilidad y consecuencia
 */
export class Gtc45CommonLevelConfigurationItem implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.IperGtc45.Dto.Gtc45CommonLevelConfigurationItem';
  readonly $type: string = Gtc45CommonLevelConfigurationItem.$type;
  Id: string;
  Title: string;
  Code: string;
  Value: number;
  MinRange: number;
  MaxRange: number;
  MinRangePercentage: number;
  MaxRangePercentage: number;
  Description: string;
}


/**
 * Dto para la configuración de niveles de riesgo
 */
export class Gtc45RiskLevelConfiguration implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.IperGtc45.Dto.Gtc45RiskLevelConfiguration';
  readonly $type: string = Gtc45RiskLevelConfiguration.$type;
  RiskLevels: { [id: string]: Gtc45RiskLevelConfigurationItem };
}


/**
 * Configuración de nivel de riesgo
 */
export class Gtc45RiskLevelConfigurationItem implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.IperGtc45.Dto.Gtc45RiskLevelConfigurationItem';
  readonly $type: string = Gtc45RiskLevelConfigurationItem.$type;
  Id: string;
  Code: string;
  Title: string;
  Description: string;
  MinRange: number;
  MaxRange: number;
  ProrrogateDays: number;
}


/**
 * Configuración de nivel de probabilidad, consecuencia y exposición
 */
export class FineCommonLevelConfigurationItem implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Ier.Modules.Fine.DTO.FineCommonLevelConfigurationItem';
  readonly $type: string = FineCommonLevelConfigurationItem.$type;
  Id: string;
  Title: string;
  Value: number;
  Description: string;
}


/**
 * Configuración de nivel de riesgo
 */
export class FineRiskLevelConfigurationItem implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Ier.Modules.Fine.DTO.FineRiskLevelConfigurationItem';
  readonly $type: string = FineRiskLevelConfigurationItem.$type;
  Id: string;
  Code: string;
  Qualification: string;
  Title: string;
  MinRange: number;
  MaxRange: number;
  Description: string;
  ProrrogateDays: number;
}


/**
 * Configuración de nivel de probabilidad y consecuencia
 */
export class InshtCommonLevelConfigurationItem implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Ier.Modules.Insht.DTO.InshtCommonLevelConfigurationItem';
  readonly $type: string = InshtCommonLevelConfigurationItem.$type;
  Id: string;
  Title: string;
  Description: string;
}


/**
 * Configuración de nivel de riesgo
 */
export class InshtRiskLevelConfigurationItem implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Ier.Modules.Insht.DTO.InshtRiskLevelConfigurationItem';
  readonly $type: string = InshtRiskLevelConfigurationItem.$type;
  Id: string;
  Code: string;
  Qualification: string;
  Title: string;
  Description: string;
  ProrrogateDays: number;
}


/**
 * Tipos de pintado del nivel de riesgo en listados
 */
export enum IerRiskLevelViewResultCellEnum {
  Code = 0,
  Title = 1,
  CodeAndTitle = 2
}


/**
 */
export enum IerStatusEnums {
  Discarded = 0,
  Identified = 1
}


/**
 * Tipos de metodologías de evaluación
 */
export enum IerEvaluationMethodologyEnum {
  INSHT = 0,
  FINE = 1,
  GTC45 = 2,
  IERC = 3
}


/**
 * Class of Permisions
 */
export class FileTemplatesPermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.FileTemplates.FileTemplatesPermissions';
  public static readonly FileTemplatesSeeModule: string = 'elemento ver';
  public static readonly FileTemplatesTemplateSee: string = 'plantillas ver';
  public static readonly FileTemplatesTemplateCreate: string = 'plantillas crear';
  public static readonly FileTemplatesTemplateEdit: string = 'plantillas editar';
  public static readonly FileTemplatesTemplateEditDocument: string = 'plantillas editar template';
  public static readonly FileTemplatesTemplateDelete: string = 'plantillas eliminar';
  public static readonly FileTemplatesTemplateCompanyDelete: string = 'plantillas por empresa eliminar';
  public static readonly FileTemplatesAccessCompanyRepository: string = 'acceder repositorio empresa';
  public static readonly FileTemplatesDownloadDocument: string = 'descargar documento';
  public static readonly FileTemplatesAccessEmployeeRepository: string = 'acceder repositorio trabajador';
  public static readonly FileTemplatesEditDocument: string = 'editar documento';
  public static readonly FileTemplatesDeleteDocument: string = 'eliminar documento';
  public static readonly FileTemplatesMostrarBotonPlantillas: string = 'mostrar boton plantillas';
  readonly $type: string = FileTemplatesPermissions.$type;
}


/**
 * EmergencyPlanStatusEnums
 */
export enum EmergencyPlanThreatStatusEnums {
  GENERAL = 0,
  SPECIFIC = 0
}


/**
 * EmergencyPlanStatusEnums
 */
export enum EmergencyPlanStatusEnums {
  VIGENTE = 0,
  ENELABORACION = 1,
  NOVIGENTE = 2
}


/**
 * SabentisModule Permissions (contants)
 */
export class QualaModulePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Quala.QualaModulePermissions';
  public static readonly SeeAllCorrectiveAction: string = 'see all corrective action';
  public static readonly SeeOwnCorrectiveActions: string = 'see own corrective action';
  public static readonly ManageCorrectiveAction: string = 'manage corrective action';
  public static readonly DeleteCorrectiveAction: string = 'delete corrective action';
  public static readonly SeeCostPrescription: string = 'see cost prescription';
  public static readonly SeeStatusHistory: string = 'see status history';
  public static readonly SeeResponsibleVerificationControl: string = 'see responsible verification control';
  public static readonly SeeSectionVerificationControl: string = 'see section verification control';
  public static readonly SeeQualaTypeMantainer: string = 'quala maintainer see';
  public static readonly AddGenericProblemMaintainer: string = 'quala generic problem add';
  public static readonly AddSpecificProblemMaintainer: string = 'quala specific problem add';
  public static readonly EditGenericProblemMaintainer: string = 'quala generic problem edit';
  public static readonly EditSpecificProblemMaintainer: string = 'quala specific problem edit';
  public static readonly DeleteGenericProblemMaintainer: string = 'quala generic problem delete';
  public static readonly DeleteSpecificProblemMaintainer: string = 'quala specific problem delete';
  public static readonly EditMaintainterQuestion: string = 'quala maintainer-questions edit';
  public static readonly DeleteMaintainterQuestion: string = 'quala maintainer-questions delete';
  public static readonly SeeGenericQuestionnaireMaintainer: string = 'quala generic questionnaire see';
  public static readonly SeeSpecificQuestionnaireMaintainer: string = 'quala specific questionnaire see';
  public static readonly AddGenericQuestionnaireMaintainer: string = 'quala generic questionnaire add';
  public static readonly AddSpecificQuestionnaireMaintainer: string = 'quala specific questionnaire add';
  public static readonly EditGenericQuestionnaireMaintainer: string = 'quala generic questionnaire edit';
  public static readonly EditSpecificQuestionnaireMaintainer: string = 'quala specific questionnaire edit';
  public static readonly DeleteGenericQuestionnaireMaintainer: string = 'quala generic questionnaire delete';
  public static readonly DeleteSpecificQuestionnaireMaintainer: string = 'quala specific questionnaire delete';
  public static readonly SeeGenericQuestionsMaintainer: string = 'quala generic questions see';
  public static readonly SeeSpecificQuestionsMaintainer: string = 'quala specific questions see';
  public static readonly SeeResponsibleApproveValidation: string = 'quala responsible approve validation see';
  public static readonly ExportYamlMaintainer: string = 'quala export yaml edit';
  readonly $type: string = QualaModulePermissions.$type;
}


/**
 * Comando que resetea la definición de quala de frontend
 */
export class QualaCommandResetQualaDefinition implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Quala.Commands.QualaCommandResetQualaDefinition';
  readonly $type: string = QualaCommandResetQualaDefinition.$type;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Quala avaible answers
 */
export enum QualaAnswerCCPNC {
  NoCumple = 0,
  Cumple = 1,
  CumpleParcialmente = 2
}


/**
 * Quala avaible answers
 */
export enum QualaAnswerCnc {
  NoCumple = 0,
  Cumple = 1
}


/**
 * NO ELIMINAR ESTE ENUM. Está asociado a configuraciones y guardado en base de datos en los formularios de cuestionarios Quala avaible answers
 */
export enum QualaAnswerYesNo {
  No = 0,
  Yes = 1
}


/**
 * Status of QUALA_PROBLEM for Manteiners
 */
export enum QualaProblemMaintainerSatusEnum {
  Active = 0,
  Disabled = 1
}


/**
 * Quala_problem type
 */
export enum QualaProblemTypeEnum {
  Generic = 0,
  Specific = 1
}


/**
 * Sí / Parcial /No
 */
export enum QualaAnswerYesPartialNo {
  Yes = 0,
  No = 1,
  Partial = 2
}


/**
 * Sí / No / No procede
 */
export enum QualaAnswerYesNoNP {
  Yes = 0,
  No = 1,
  NP = 2
}


/**
 * Enumeration of the status of a pre-identified problem in relation to its antiquity.
 */
export enum PreidentifiedProblemsAntiquityEnum {
  Active = 0,
  Outdated = 1
}


/**
 * Enum for identified source
 */
export enum PreidentifiedProblemsSourceEnum {
  System = 0,
  ByUser = 1
}


/**
 * Quala avaible answers
 */
export enum QualaAnswerYesNoNA {
  No = 0,
  Yes = 1,
  NA = 2
}


/**
 * Quala Non Compliance Type Enums
 */
export enum QualaNonComplianceTypeEnums {
  Leve = 0,
  Grave = 1,
  MuyGrave = 2
}


/**
 * The Quala Problem source
 */
export enum QualaProblemSourceEnums {
  Manual = 0,
  App = 1
}


/**
 * The Quala Reason status
 */
export enum QualaReasonStatusEnums {
  Discarded = 0,
  PendingValidation = 1,
  Validated = 2
}


/**
 * Este enum se usa para los estados de Validación, Verificación, Implantación y Aprobación de una medida  formData.CorrectiveAction.approval = QualaCorrectiveActionStatesEnums.NoAplica.ToByte(); formData.CorrectiveAction.implantation = QualaCorrectiveActionStatesEnums.NoAplica.ToByte(); formData.CorrectiveAction.validation = QualaCorrectiveActionStatesEnums.NoAplica.ToByte(); formData.CorrectiveAction.verification = QualaCorrectiveActionStatesEnums.NoAplica.ToByte();
 */
export enum QualaCorrectiveActionStatesEnums {
  NoAplica = 0,
  Aprobada = 1,
  Rechazada = 2,
  Pendiente = 3,
  Realizada = 4,
  Validada = 5
}


/**
 * QualaCorrectiveActionResultVerificationEnums
 */
export enum QualaCorrectiveActionResultVerificationEnums {
  CorrectaPlanificada = 0,
  CorrectaEquivalenteOSuperiorPlanificada = 1,
  DeficientePlanificada = 2,
  DeficienteEquivalenteOSuperiorPlanificada = 3
}


/**
 * QualaCorrectiveActionImplementationTypeEnums
 */
export enum QualaCorrectiveActionValidationTypeEnums {
  Documental = 0,
  EnTerreno = 1,
  DocumentalYEnTerreno = 2
}


/**
 * QualaCorrectiveActionResultValidationEnums
 */
export enum QualaCorrectiveActionResultValidationEnums {
  CorrectaPlanificada = 0,
  CorrectaEquivalenteOSuperiorPlanificada = 1,
  Deficiente = 2
}


/**
 * QualaCorrectiveActionImplementationTypeEnums
 */
export enum QualaCorrectiveActionImplementationTypeEnums {
  Planificada = 0,
  EquivalenteOSuperiorPlanificada = 1
}


/**
 * Enum for priority field in corrective actions with color
 */
export enum QualaCorrectiveActionPriority {
  Green = 0,
  Yellow = 1,
  Orange = 2,
  Red = 3
}


/**
 * ESTO ESTÁ COMPARTIDO Y VINCULADO AL CORE DE LA PLATAFORMA, NO SE PUEDE TOCAR.  FAVOR NO AÑADIR NI QUITAR ESTADOS, ES LO QUE HAY.
 */
export enum QualaCorrectiveActionStatusNewEnums {
  PendienteDeDefinicion = 0,
  PendienteDePlanificacion = 5,
  PendienteDeAprobacion = 10,
  EnImplementacion = 15,
  PendienteDeEvidencia = 20,
  PendienteDeValidacion = 25,
  PendienteDeVerificacion = 30,
  Cerrada = 35,
  CerradaFueraDePlazo = 40,
  Rechazada = 45,
  NoImplementada = 50
}


/**
 * The Quala Questionaire frecuency
 */
export enum QualaCorrectiveActionFrecuencyEnums {
  Periodica = 0,
  Puntual = 1
}


/**
 * Keys for responsable of corrective actions
 */
export class QualaCorrectiveActionResponsableTypes implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Quala.Enums.QualaCorrectiveActionResponsableTypes';
  public static readonly Implementacion: string = 'implementacion';
  public static readonly Aprobacion: string = 'aprobacion';
  public static readonly Validacion: string = 'validacion';
  public static readonly Verificacion: string = 'verificacion';
  readonly $type: string = QualaCorrectiveActionResponsableTypes.$type;
}


/**
 * Quala cost types of cost Usar solo para los listados para el formulario usar la configuración.
 */
export enum QualaCostTypeEnums {
  MedidasEliminacion = 0,
  MedidasSustitucion = 1,
  MedidasIngenieril = 2,
  MedidasCapacitacionCurso = 3,
  MedidasCapacitacionCharla = 4,
  MedidasSeñalizacion = 5,
  MedidasProteccionPersonal = 6
}


/**
 * Quala avaible answers
 */
export enum QualaAnswerCcna {
  NoCumple = 0,
  Cumple = 1,
  NoAplica = 2
}


/**
 * The Quala Assignation types: LOCATION, JOB, etc.
 */
export enum QualaAssignationTypesEnums {
  LOCATION = 0,
  JOB = 1,
  GES = 2,
  RISKRELEASE = 3
}


/**
 * Enum with the differents types Business Errors
 */
export enum QualaBusinessErrorTypeEnum {
  Fatal = 0,
  Warning = 1
}


/**
 * The Quala Copy types for assignations and evaluations
 */
export enum QualaCopyTypeEnum {
  ALL = 1,
  COMPLEMENT = 2
}


/**
 * The Quala Evaluation status
 */
export enum QualaCorrectiveActionExecutionEnums {
  Approved = 1,
  Rejected = 2,
  PendingApproval = 3
}


/**
 * The Quala Evaluation status
 */
export enum QualaEvaluationStatusEnums {
  Controlled = 0,
  NotControlled = 1
}


/**
 * The Quala Problem status
 */
export enum QualaProblemStatusEnums {
  Discarded = 0,
  Identified = 1,
  Disidentified = 2,
  NotManaged = 3,
  PendingValidation = 4,
  Validated = 5
}


/**
 * The Quala Questionaire origin
 */
export enum QualaQuestionnaireOriginEnums {
  Usuario = 0,
  Sistema = 1
}


/**
 * The Quala types: IPER, AUDIT, etc.
 */
export enum QualaStepsEnum {
  Studies = 0,
  AvaibleQuestionnnaires = 1,
  ShowInventory = 2,
  AssignProblems = 3,
  EvaluateProblems = 4,
  RiskEvaluation = 5
}


/**
 * TODO: La manera de descentralizar los QUALA será con una nueva tabla QUALA_QUALA de manera que cada módulo declare sú quala a través de un plugin (ya existe un plugin para esto, el QualaTools) y esto quede persistido en BBDD con información sobre el módulo propietario del quala
 */
export enum QualaTypesEnums {
  HIRA = 0,
  GES = 1,
  AUDIT = 2,
  IER = 3,
  ACHSE11 = 4,
  EP = 5,
  IS = 6,
  ACHSGES = 7,
  PYMESIPER = 8,
  RISKRELEASE = 9,
  ERGO = 10,
  OPT = 11,
  ABS_CON = 12,
  ABS_INC = 13,
  MANUAL = 99
}


/**
 * Keys for single item operations
 */
export class SingleItemOperationTypes implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Quala.Enums.SingleItemOperationTypes';
  public static readonly Edit: string = 'edit';
  public static readonly Remove: string = 'remove';
  public static readonly Recover: string = 'recover';
  public static readonly Copy: string = 'copy';
  public static readonly Details: string = 'details';
  public static readonly Answer: string = 'answer';
  public static readonly Desassociate: string = 'Desassociate';
  public static readonly Discard: string = 'discard';
  public static readonly ManageGES: string = 'ManageGES';
  public static readonly Personalize: string = 'Personalize';
  public static readonly Evaluate: string = 'Evaluate';
  public static readonly Select: string = 'select';
  public static readonly Validate: string = 'validate';
  readonly $type: string = SingleItemOperationTypes.$type;
}


/**
 * Quala Definition. Is the Dto necessary to e xchange the information
 */
export class QualaDefinition implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Quala.Dto.QualaDefinition';
  readonly $type: string = QualaDefinition.$type;
  AvailableSteps: QualaStepsEnum[];
  Study: QUALA_STUDY;
  StudyDefinitionPluginBuilder: string;
  StudiesListPluginId: string;
  StudyEditFormPluginId: string;
  StudyDeleteFormPluginId: string;
  QuestionnaireViewPluginId: string;
  QuestionnaireAddViewPluginId: string;
  QuestionnaireFormPluginId: string;
  QuestionnaireItemSummaryFormPlugin: string;
  InventoryListPlugin: string;
  InventoryItemSummaryFormPlugin: string;
  InventoryItemDesassociateFormPlugin: string;
  AssignProblemSearchListPlugin: string;
  AssignProblemProblemSearchListPlugin: string;
  AssignProblemCurrentLocationListPlugin: string;
  AssignProblemProblemListPlugin: string;
  AssignProblemTopListPlugin: string;
  AssignProblemBottomListPlugin: string;
  AssignProblemsToLocationListPlugin: string;
  AssignLocationsToProblemListPlugin: string;
  DeleteQuestionnaireFormPlugin: string;
  PersonalizeProblemFormPlugin: string;
  DeleteAssignationProblemFormPlugin: string;
  DeleteCorrectiveActionFormPlugin: string;
  EvaluateProblemListPlugin: string;
  EvaluateProblemTopListPlugin: string;
  EvaluateProblemBottomListPlugin: string;
  EvaluateProblemProblemListPlugin: string;
  EvaluateProblemSearchListPlugin: string;
  EvaluateProblemSearchProblemListPlugin: string;
  EvaluateProblemFormPlugin: string;
  CorrectiveActionListPlugin: string;
  PredefinedCorrectiveActionListPlugin: string;
  RiskEvaluationFormPlugin: string;
  Signature: string;
}


/**
 * ContractorsModule Permissions (contants)
 */
export class ContractorsModulePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.Contractors.ContractorsModulePermissions';
  public static readonly SeeContractor: string = 'see contractor';
  public static readonly DeleteProjects: string = 'delete projects';
  public static readonly SeeRequirementsLicitacion: string = 'see requirements licitacion';
  public static readonly EvaluateRequirementsLicitacion: string = 'evaluate requirements licitacion';
  public static readonly FinalizeRequirementsLicitacion: string = 'finalize requirements licitacion';
  public static readonly EditDocumentsContractor: string = 'edit documents contractor';
  public static readonly SeeSelectCompaniesContractorLicitacion: string = 'see select companies contractor licitacion';
  public static readonly SelectCompaniesContractorLicitacion: string = 'select companies contractor licitacion';
  public static readonly FinalizeLicitacion: string = 'finalize licitacion';
  public static readonly SeeRequirementsConfiguration: string = 'see requirements configuration';
  public static readonly CreateRequirementsMaintainer: string = 'create requirements maintainer';
  public static readonly EditRequirementsCompany: string = 'edit requirements company';
  public static readonly EditRequirementsAdmin: string = 'edit requirements admin';
  public static readonly RequirementsCompanyDelete: string = 'contractors requirement company delete';
  public static readonly RequirementAdministratorDelete: string = 'contractors requirement administrator delete';
  public static readonly SeeCompaniesMaintainer: string = 'see companies maintainer';
  public static readonly CreateCompaniesMaintainer: string = 'create companies maintainer';
  public static readonly EditCompaniesMaintainer: string = 'edit companies maintainer';
  public static readonly DeleteCompaniesMaintainer: string = 'delete companies maintainer';
  public static readonly SeeRequirementsHabilitacion: string = 'see requirements habilitacion';
  public static readonly EvaluateRequirementsHabilitacion: string = 'evaluate requirements habilitacion';
  public static readonly SeeRequirementsOperacion: string = 'see requirements operacion';
  public static readonly EvaluateRequirementsOperacion: string = 'evaluate requirements operacion';
  public static readonly ManageProjectsIncidents: string = 'manage projects incidents';
  public static readonly EditProjectsIncidents: string = 'edit projects incidents';
  public static readonly CreateProjectsIncidents: string = 'create projects incidents';
  public static readonly DeleteProjectsIncidents: string = 'contractors incidents delete';
  public static readonly EditProjectIncidentState: string = 'contractors incident state edit';
  public static readonly EditProjectsActivities: string = 'edit projects activities';
  public static readonly SeeProjectsWarnings: string = 'see projects warnings';
  public static readonly ManageProjectsWarnings: string = 'manage projects warnings';
  public static readonly CreateProjectsWarnings: string = 'create projects warnings';
  public static readonly DeleteProjectsWarnings: string = 'delete projects warnings';
  public static readonly SelectCompaniesContractorHabilitacion: string = 'select companies contractor habilitacion';
  public static readonly FinalizeHabilitacion: string = 'finalize habilitacion';
  public static readonly SeeHabilitacionCompaniesContractorHabilitacion: string = 'see habilitacion companies contractor habilitacion';
  public static readonly FinalizeRequirementsHabilitacion: string = 'finalize requirements habilitacion';
  public static readonly ManageHabilitacionCompaniesEmployeesHabilitacion: string = 'manage habilitacion companies employees habilitacion';
  public static readonly SeeProjectsControlAccesos: string = 'see projects control accesos';
  public static readonly EditControlAccesos: string = 'edit control accesos';
  public static readonly SeeAllProjectsByCompany: string = 'see all projects by company';
  public static readonly EditOwnProjectsByCompany: string = 'edit own projects by company';
  public static readonly SeeMiEmpresa: string = 'see contractor by company';
  public static readonly EditContractorByCompany: string = 'edit contractor by company';
  public static readonly SeeControlAccesos: string = 'see control accesos';
  public static readonly SeeAllProjects: string = 'contractors project see all';
  public static readonly SeeOwnProjects: string = 'contractors project see own';
  public static readonly CreateProjects: string = 'contractors project create';
  public static readonly SeeRequirementsLicitacionTab: string = 'contractors requirements bidding tab see';
  public static readonly SeeApplicantCompaniesLicitacion: string = 'contractors applicant companies bidding see';
  public static readonly EditDataProjectsBidding: string = 'contractors data projects bidding edit';
  public static readonly EditRequirementsBidding: string = 'contractors requirements bidding edit';
  public static readonly EditApplicantCompaniesLicitacion: string = 'contractors applicant companies bidding edit';
  public static readonly SeeRequirementsHabilitationTab: string = 'contractors requirements habilitation tab see';
  public static readonly SeeContractorCompaniesHabilitation: string = 'contractors contractor companies habilitation see';
  public static readonly SeeWorkersHabilitation: string = 'contractors workers habilitation see';
  public static readonly SeeFastRequirementLoadCompanyMain: string = 'contractors company main fast requirement load see';
  public static readonly SeeFastRequirementLoadWorkerMain: string = 'contractors worker main fast requirement load see';
  public static readonly SeeFastRequirementLoadCompany: string = 'contractors company fast requirement load see';
  public static readonly SeeFastRequirementLoadWorker: string = 'contractors worker fast requirement load see';
  public static readonly EditDataProjectHabilitation: string = 'contractors data project habilitation edit';
  public static readonly EditRequirementsHabilitation: string = 'contractors requirements habilitation edit';
  public static readonly EditContractorCompaniesHabilitation: string = 'contractors contractor companies habilitation edit';
  public static readonly EditWorkersHabilitation: string = 'contractors workers habilitation edit';
  public static readonly SeeRequirementsOperationTab: string = 'contractors requirements operation tab see';
  public static readonly SeeContractorCompaniesOperation: string = 'contractors contractor companies operation see';
  public static readonly SeeWorkersOperation: string = 'contractors workers operation see';
  public static readonly EditDataProjectOperation: string = 'contractors data project operation edit';
  public static readonly EditRequirementsOperation: string = 'contractors requirements operation edit';
  public static readonly EditContractorCompaniesOperation: string = 'contractors contractor companies operation edit';
  public static readonly EditWorkersOperation: string = 'contractors workers operation edit';
  public static readonly SeeProjectIncidentsControlMeasures: string = 'contractors control measures see';
  public static readonly EvaluateProjectControlMeasures: string = 'contractors control measures evaluate';
  public static readonly CreateProjectControlMeasuresIncident: string = 'contractors control measures create';
  public static readonly ManageProjectControlMeasures: string = 'contractors control measures manage';
  public static readonly DeleteControlMeasures: string = 'contractors control measures delete';
  public static readonly CompaniesBulkLoadOperation: string = 'contractors company bulk load button';
  public static readonly WorkersBulkLoadOperation: string = 'contractors workers bulk load button';
  public static readonly ManageContractorResponsibles: string = 'contractors responsibles manage';
  public static readonly WorkersMyCompanieBulkLoadOperation: string = 'contractors workers my companie bulk load button';
  public static readonly ManageRequirementsApply: string = 'contractors requirements apply manage';
  public static readonly SeeRequirementHistory: string = 'contractors see requirements history';
  public static readonly DeleteRequirementHistory: string = 'contractors delete requirements history';
  public static readonly EditRequirementExpirationDate: string = 'contractors edit requirements expiration date';
  readonly $type: string = ContractorsModulePermissions.$type;
}


/**
 * Este ENUM es el de las fases de un proyecto, pero tiene las claves de la fase que se usan en la navegación para identificar una u otra fase
 */
export enum PhasesProjectCodeEnum {
  LICITACION = 0,
  HABILITACION = 1,
  OPERACION = 2
}


/**
 * Enum Descargar o No cargado
 */
export enum StatusEvaluationDownloadEnum {
  NoCargado = 0,
  Descargar = 1
}


/**
 * Enum de los posibles estados de control de acceso
 */
export enum AccesControlEnum {
  DENEGADO = 0,
  PERMITIDO = 1
}


/**
 */
export enum ContractorIncidentConsequencesTypeEnum {
  TRABAJO = 0,
  GRAVE = 1,
  FATAL = 2
}


/**
 */
export enum ContractorIncidentCircumstancesTypeEnum {
  DOINGTASKS = 0,
  ONROUTE = 1
}


/**
 * Enum de los posibles origenes de los incidentes de un proyecto
 */
export enum ContractorIncidentOriginPeople {
  EMPRESA = 0,
  EXTERNAS = 1
}


/**
 */
export enum ContractorIncidentTypeEnum {
  NOTWASTEDTIMEACCIDENT = 0,
  WASTEDTIMEACCIDENT = 1
}


/**
 * Enum contractor company type
 */
export enum ContractorCompanyTypeEnum {
  CONTRACTOR = 0,
  SUBCONTRACTOR = 1
}


/**
 * Enum de los estados de una actividad de control
 */
export enum StatusActivityEnum {
  WithoutEvidence = 0,
  Pending = 1,
  Approve = 2,
  Refuse = 3
}


/**
 */
export enum ContractorWarningReasonEnum {
  BreachRequirement = 0,
  BreachControlActivity = 1
}


/**
 * Enum de la frecuencia de una actividad de control
 */
export enum FrequencyActivityEnum {
  PUNTUAL = 0,
  PERIODICA = 1
}


/**
 */
export enum StatusEmployeesEnum {
  INACTIVE = 0,
  ACTIVE = 1
}


/**
 * Enum de los estados de la fase
 */
export enum StatusPhaseProjectEnum {
  CLOSED = 0,
  OPEN = 1
}

/**
 * Enum de los posibles origenes de un proyecto
 */
export enum CategoryRequirementEnum {
  EMPRESA = 0,
  TRABAJADOR = 1
}


/**
 * Enum de los posibles origenes de un proyecto
 */
export enum OriginProjectEnum {
  ADMIN = 0,
  EMPRESAPRINCIPAL = 1
}


/**
 * Enum de los estados de un proyecto  El estado pendiente de evaluación se solicito como cambio transversal en la US ACHSPRIME-6483
 */
export enum StatusRequirementEnum {
  PENDIENTE = 0,
  APROBADO = 1,
  RECHAZADO = 2,
  CADUCADO = 3,
  SINFICHERO = 4,
  NOAPLICA = 5
}


/**
 * Enum de las fases de un proyecto
 */
export enum PhasesProjectEnum {
  LICITACION = 0,
  HABILITACION = 1,
  OPERACION = 2
}


/**
 * PrintLabelCommand
 */
export class PrintLabelCommand implements IViewRowOperationsCommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Modules.CoreTests.Plugin.Command.PrintLabelCommand';
  readonly $type: string = PrintLabelCommand.$type;
  Field: string;
  Label: string;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
  Result: ViewResultRow;
}


/**
 */
export class Tuple<T1, T2> implements ITypedObject {
  public static readonly $type: string = 'System.Tuple\`2';
  readonly $type: string = Tuple.$type;
  Item1: T1;
  Item2: T2;
}


/**
 */
export class KeyValuePair<TKey, TValue> implements ITypedObject {
  public static readonly $type: string = 'System.Collections.Generic.KeyValuePair\`2';
  readonly $type: string = KeyValuePair.$type;
  Key: TKey;
  Value: TValue;
}


/**
 */
export enum Precision {
  Years = 0,
  Months = 1,
  Days = 2,
  Hours = 3,
  Minutes = 4,
  Seconds = 5,
  Milliseconds = 6,
  Ticks = 7
}

